import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Swiper from "../../node_modules/swiper/swiper-bundle.min.js"


const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allStrapiArticle.edges
  const featuredPosts = data.featured.edges
  const recentPosts = data.recent.edges
  const reactPosts = data.react.edges
  const angularPosts = data.angular.edges
  const vuePosts = data.vue.edges

  useEffect(() => {
    var swiper = new Swiper(".swiper-container", {
      slidesPerView: 1,
      spaceBetween: 30,
      centered: true,
      centeredSlides: true,
      loop: true,
      speed: 1500,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        "@1.25": {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        "@1.50": {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        "@2.00": {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      },
    })
  }, [])

  const text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" />
      <div className="featured-posts-wrapper">
        <h1 class="uk-heading-line uk-text-center">
          <span>Featured</span>
        </h1>
        <div class="swiper-container">
          <div class="swiper-wrapper">
            {
              featuredPosts.map(post => {
                const title = text_truncate(post.node.title, 80, '...')
                const date = new Date(post.node.updatedAt)
                return (
                  <div class="swiper-slide" key={post.node.slug}>
                    <Link to={post.node.slug}>
                      <img className='slider-element' src={post.node.hero_image.url} alt={post.node.hero_image.alternativeText}></img>
                      <div className='featured-post'>
                        <h3 className='featured-post-title'>{title}</h3>
                        <div className='featured-post-date'>
                          <small className='date'>{date.toLocaleDateString()}</small>
                          <small className='date'>{post.node.ttr.childMdx.timeToRead} min read</small>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <div className="recent-posts-wrapper">
        <h1 class="uk-heading-line uk-text-left">
          <span>Recent Posts</span>
        </h1>
        <div className="post-wrapper">
          {
            recentPosts.map(post => {
              const title = text_truncate(post.node.title, 50, '...')
              const date = new Date(post.node.updatedAt)

              return (
                <div className="post-container" key={post.node.slug}>
                  <Link to={post.node.slug}>
                    <div class="uk-text-center uk-visible@s">
                      <div class="uk-inline-clip uk-transition-toggle uk-light" tabIndex="0">
                        <img className='recent-post-image' src={post.node.hero_image.url + '?tr=w-390'} alt=""></img>
                        <div class="uk-position-center">
                          <div class="uk-transition-slide-top-small"><h4 class="featured-post-title">{title}</h4></div>
                          <div class="uk-transition-slide-bottom-small">
                            <div className='featured-post-date'>
                              <small className='date'>{date.toLocaleDateString()}</small>
                              <small className='date'>{post.node.ttr.childMdx.timeToRead} min read</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='uk-hidden@s'>
                      <img className='recent-post-image' src={post.node.hero_image.url + '?tr=w-390'} alt={post.node.hero_image.alternativeText} ></img>
                      <div className='featured-post'>
                        <h3 className='featured-post-title'>{title}</h3>
                        <div className='featured-post-date'>
                          <small className='date'>{date.toLocaleDateString()}</small>
                          <small className='date'>{post.node.ttr.childMdx.timeToRead} min read</small>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })
          }
        </div>
      </div>
      <h1 class="uk-heading-line uk-text-left">
        <span>ReactJs</span>
      </h1>
      <div className="category-post-container">
        <div class="uk-child-width-1-2@m" data-uk-grid>
          {
            reactPosts.map(post => {
              const desc = text_truncate(post.node.meta_description, 100, '...')
              const title = text_truncate(post.node.title, 40, '...')
              return (
                <Link to={post.node.slug} key={post.node.slug}>
                  <div class="uk-card uk-card-default uk-hidden@s">
                    <div class="uk-card-media-top">
                      <img src={post.node.hero_image.url} alt={post.node.hero_image.alternativeText}></img>
                    </div>
                    <div class="uk-card-body">
                      <h3 class="uk-card-title">{title}</h3>
                      <p>{desc}</p>
                    </div>
                  </div>
                  <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin uk-visible@s" data-uk-grid>
                    <div class="uk-card-media-left uk-cover-container">
                      <img src={post.node.hero_image.url} alt={post.node.hero_image.alternativeText} data-uk-cover></img>
                      <canvas width="600" height="400"></canvas>
                    </div>
                    <div>
                      <div class="uk-card-body">
                        <h3 class="uk-card-title">{title}</h3>
                        <p>{desc}</p>
                      </div>
                    </div>
                  </div>
                </Link >
              )
            })
          }
        </div>
      </div>
      <h1 class="uk-heading-line uk-text-left">
        <span>Angular</span>
      </h1>
      <div className="category-post-container">
        <div class="uk-child-width-1-2@m" data-uk-grid>
          {
            angularPosts.map(post => {
              const desc = text_truncate(post.node.meta_description, 100, '...')
              const title = text_truncate(post.node.title, 40, '...')
              return (
                <Link to={post.node.slug} key={post.node.slug}>
                  <div class="uk-card uk-card-default uk-hidden@s">
                    <div class="uk-card-media-top">
                      <img src={post.node.hero_image.url} alt={post.node.hero_image.alternativeText}></img>
                    </div>
                    <div class="uk-card-body">
                      <h3 class="uk-card-title">{title}</h3>
                      <p>{desc}</p>
                    </div>
                  </div>
                  <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin uk-visible@s" data-uk-grid>
                    <div class="uk-card-media-left uk-cover-container">
                      <img src={post.node.hero_image.url} alt={post.node.hero_image.alternativeText} data-uk-cover></img>
                      <canvas width="600" height="400"></canvas>
                    </div>
                    <div>
                      <div class="uk-card-body">
                        <h3 class="uk-card-title">{title}</h3>
                        <p>{desc}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            })
          }
        </div>
      </div>
      <h1 class="uk-heading-line uk-text-left">
        <span>Vue</span>
      </h1>
      <div className="category-post-container">
        <div class="uk-child-width-1-2@m" data-uk-grid>
          {
            vuePosts.map(post => {
              const desc = text_truncate(post.node.meta_description, 100, '...')
              const title = text_truncate(post.node.title, 40, '...')
              return (
                <Link to={post.node.slug} key={post.node.slug}>
                  <div class="uk-card uk-card-default uk-hidden@s">
                    <div class="uk-card-media-top">
                      <img src={post.node.hero_image.url} alt={post.node.hero_image.alternativeText}></img>
                    </div>
                    <div class="uk-card-body">
                      <h3 class="uk-card-title">{title}</h3>
                      <p>{desc}</p>
                    </div>
                  </div>
                  <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin uk-visible@s" data-uk-grid>
                    <div class="uk-card-media-left uk-cover-container">
                      <img src={post.node.hero_image.url} alt={post.node.hero_image.alternativeText} data-uk-cover></img>
                      <canvas width="600" height="400"></canvas>
                    </div>
                    <div>
                      <div class="uk-card-body">
                        <h3 class="uk-card-title">{title}</h3>
                        <p>{desc}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            })
          }
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiArticle(filter: { status: { eq: "published" } }) {
      edges {
        node {
          strapiId
          slug
          title
          content
          updatedAt
        ttr:childStrapiArticleContent {
          childMdx {
            timeToRead
          }
        }
          hero_image {
            url
            alternativeText
          }
        }
      }
    }
    featured: allStrapiArticle(filter: {status: {eq: "published"},featured:{eq: true}}) {
      edges {
        node {
          strapiId
          slug
          title
          content
          updatedAt
        ttr:childStrapiArticleContent {
          childMdx {
            timeToRead
          }
        }
          hero_image {
            url
            alternativeText
          }
        }
      }
    }
    recent: allStrapiArticle(filter: {status: {eq: "published"}}, sort: {fields: updatedAt, order: ASC},limit:6) {
      edges {
        node {
          strapiId
          slug
          title
          content
          updatedAt
        ttr:childStrapiArticleContent {
          childMdx {
            timeToRead
          }
        }
          hero_image {
            url
            alternativeText
          }
        }
      }
    }
    react: allStrapiArticle(filter: {status: {eq: "published"},tags: {elemMatch: {name: {eq: "React"}}}}, sort: {fields: updatedAt, order: ASC},limit:6) {
      edges {
        node {
          strapiId
          slug
          title
          content
          meta_description
          updatedAt
        ttr:childStrapiArticleContent {
          childMdx {
            timeToRead
          }
        }
          hero_image {
            url
            alternativeText
          }
        }
      }
    }
    angular:  allStrapiArticle(filter: {status: {eq: "published"},tags: {elemMatch: {name: {eq: "Angular"}}}}, sort: {fields: updatedAt, order: ASC},limit:6) {
      edges {
        node {
          strapiId
          slug
          title
          content
          meta_description
          updatedAt
        ttr:childStrapiArticleContent {
          childMdx {
            timeToRead
          }
        }
          hero_image {
            url
            alternativeText
          }
        }
      }
    }
    vue: allStrapiArticle(filter: {status: {eq: "published"},tags: {elemMatch: {name: {eq: "Vue"}}}}, sort: {fields: updatedAt, order: ASC},limit:6) {
      edges {
        node {
          strapiId
          slug
          title
          content
          meta_description
          updatedAt
        ttr:childStrapiArticleContent {
          childMdx {
            timeToRead
          }
        }
          hero_image {
            url
            alternativeText
          }
        }
      }
    }
  }
`
